'use strict';

angular.module('kljDigitalLibraryApp')
    .service('caseNotesService', ["$q", "common", "appConfig",
        function($q, common, appConfig) {

            var baseUrl = '/api/users';

            this.getCaseNotesList = function(payload) {

                var deferred = $q.defer();

                common.callApi('POST',
                    baseUrl + '/get/caseNotesList',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    payload)

                .then(function(response) {

                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                });

                return deferred.promise;
            }

            this.removeCaseNotes = function(id) {

                var deferred = $q.defer();

                common.callApi('DELETE',
                    baseUrl + '/delete/caseNotes/' + id,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {
                    deferred.resolve(response);
                })

                .catch(function(error) {

                    deferred.reject(error);
                });

                return deferred.promise;
            }

            this.save = function(payload) {


                return common.callApi('PUT',
                    baseUrl + '/update/caseNotes',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    payload
                )

                .then(function(response) {
                    return (response.data);
                })
            }


        }
    ]);